

























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { savedSearchModule } from '@/store/modules/savedSearch';
import { projectModule } from '@/store/modules/project';
import { typeProject } from '@/filters/type-project';

@Component({
    filters: { typeProject }
})
export default class Discarded extends Vue {
    project = null;

    filter: { page: number; discarded: string[] } = {
        page: 1,
        discarded: []
    };

    get discarded() {
        return projectModule.discarded;
    }

    get projectsFromBuilder() {
        return projectModule.projectsFromBuilder;
    }

    get link() {
        if (savedSearchModule.savedSearchSelected)
            return `/saved-search/${savedSearchModule.savedSearchSelected._id}`;
        return '/search';
    }

    get business() {
        if (!projectModule.discarded.data) return [];
        return projectModule.discarded.data.reduce((array, item) => {
            if (
                !array.find(
                    (element: any) => element.tradename === item.tradename
                )
            ) {
                array.push(item);
            }
            return array;
        }, []);
    }

    async mounted() {
        if (this.$route.params.id) {
            await projectModule.getSavedSearch(this.$route.params.id);
        }
        this.changeFilter();
    }

    clearProjects() {
        projectModule.setProjectsFromBuilder([]);
    }

    getProjectsFromBuilder(id?: number) {
        if (id) projectModule.getProjectsFromBuilder(id);
    }

    changeProject(id?: string) {
        if (id) this.filter.discarded = [id];
        else this.filter.discarded = [];
    }

    changeFilter() {
        projectModule.getDiscarded(this.filter);
    }

    add(row: any) {
        projectModule.addProject(row._id);
    }

    linkToProject(row: any) {
        if (savedSearchModule.savedSearchSelected)
            return `/project-saved-search/${row.project_id}/${savedSearchModule.savedSearchSelected._id}`;
        return `/project/${row.project_id}`;
    }
}
